/*
############     _text-area.scss      ############
*/

textarea {
  border: $formBorder;
  border-radius: $formBorderRadius;
  font-family: $fixedWidthFont;
  font-weight: $fixedWidthFontBaseWeight;
  box-sizing: border-box;
  min-height: 100px;

  @include formElementSizes;

  .stack-form & {
    @include formElementSizesStacked;
  }
}