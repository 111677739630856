.file-input {
  &--skin {
    input[type=file] {
      visibility: hidden;
    }
  }

  &__skin-wrapper {
    display: inline-block;
  }
}