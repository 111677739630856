/*
############     _input-container.scss      ############
*/
.input-container {
  margin: 0 0 $global-spacing-unit 0;
  $labelSelector: "label:not(.form-error)";

  #{$labelSelector} {
    margin: 0 20px 0 0;
    display: inline-block;
    width: $labelWidth;
    vertical-align: middle;
    text-align: right;

    .stack-form & {
      display: block;
      width: $labelWidthFull;
      text-align: left;
      margin-bottom: 12px;
    }
  }

  .half-form & {
    width: calc(50% - .5rem);

    &:nth-child(odd) {
      margin-right: 1rem;
    }
  }

  &.text-area {
    clear: both;

    #{$labelSelector} {
      vertical-align: top;
    }
  }

  &.required {
    #{$labelSelector}:after {
      content: "*";
      color: $red;
      display: inline-block;
      margin: 0 .5rem 0 0;
    }
  }

  table & {
    margin: 0;
  }

  input[type=text].mask-date.size-full {
    width: calc(100% - 32px);
  }
}