.legend {
  height: 100%;

  &--item {
    display: flex;
    align-items: baseline;
    margin: $global-spacing-unit-small 0;

    &--symbol {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: $global-spacing-unit-small;
    }

    span {
      width: calc(100% - 15px - .5rem);
    }
  }
}

.criteria {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  li {
    width: 50%;
  }
}

