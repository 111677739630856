/*
############     _utilities.scss      ############
utility classes, atomic css
*/

@import "animation";

/* --------- Mixin --------- */
@mixin generateBackgrounds() {
  @for $i from 1 through length($colorGraphList) {
    $firstColor: nth($colorGraphList, $i);
    $secondColor: nth($colorGraphLightList, $i);

    &--color#{$i - 1} {
      background-color: $firstColor;

      &.lines {
        background: repeating-linear-gradient(
                        -45deg,
                        $firstColor,
                        $firstColor 5px,
                        $secondColor 5px,
                        $secondColor 10px
        );
      }
    }
  }
}

.background {
  @include generateBackgrounds();
}

/* --------- Flex --------- */
.flex {
  display: flex;

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-flex-start {
    justify-content: flex-start;
  }

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.align-items-start {
    align-items: flex-start;
  }

  &.align-items-end {
    align-items: flex-end;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-stretch {
    align-items: stretch;
  }

  &.align-items-baseline {
    align-items: baseline;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.direction-col {
    flex-direction: column;
  }

  .fifty {
    width: 50%;
  }
}

.flex1 {
  flex: 1 1 0;
}

.flex2 {
  flex: 2 1 0;
}

/* --------- Text Classes --------- */
.text-center, .center-text {
  text-align: center;
}

.text-right, .right-text {
  text-align: right;
}

.text-left, .left-text {
  text-align: left;
}

.text-red, .red-text {
  color: $red;
}

.text-standard {
  font-size: 1rem;
}

.text-medium {
  font-size: 1.25rem;
}

.red {
  fill: $red;
}

.green {
  fill: $secondaryColor;
}

.blue {
  fill: $primaryColor;
}

.text-bold, .bold-text {
  font-weight: $boldFontWeight;
}

.text-italic, .italic-text {
  font-style: italic;
}

.word-break {
  word-break: break-word;
}

/* ---- Foreground and Background Utility Colors ---- */
.color-primary {
  color: $primaryColor;
}

.color-secondary {
  color: $secondaryColor;
}

.color-tertiary {
  color: $tertiaryColor;
}

.color-quaternary {
  color: $quaternaryColor;
}

.color-neutral {
  color: $neutralColor;
}

.color-complimentary {
  color: $complementaryColor;
}

.color-white {
  color: white;
}

.color-grey {
  color: $gray50;
}

.bg-primary {
  background-color: $primaryColor;
}

.bg-secondary {
  background-color: $secondaryColor;
}

.bg-tertiary {
  background-color: $tertiaryColor;
}

.bg-quaternary {
  background-color: $quaternaryColor;
}

.bg-neutral {
  background-color: $neutralColor;
}

.bg-complimentary {
  background-color: $complementaryColor;
}

/* ----- Positioning ---- */
.hcenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.vcenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.vcenter.right {
  right: 0;
}

.vcenter.left {
  left: 0;
}

.vcenter.hcenter {
  transform: translate(-50%, -50%);
}

.hcenter-margin {
  display: block;
  margin: 0 auto;
}

/* ---- Spacing and Padding --- */
.mt-spacing {
  margin-top: $global-spacing-unit;
}

.mb-spacing {
  margin-bottom: $global-spacing-unit;
}

.mb-spacing--small {
  margin-bottom: $global-spacing-unit-small;
}

.mr-spacing {
  margin-right: $global-spacing-unit;
}

.ml-spacing {
  margin-left: $global-spacing-unit;
}

.mt-zero {
  margin-top: 0;
}

.mb-zero {
  margin-bottom: 0;
}

.mr-zero {
  margin-right: 0;
}

.ml-zero {
  margin-left: 0;
}

.m-none {
  margin: 0;
}

.mr-spacing--small {
  margin-right: $global-spacing-unit-tiny;
}

.mr-spacing--large {
  margin-right: $global-spacing-unit-huge;
}

.p-spacing {
  padding: $global-spacing-unit;
}

.pt-spacing {
  padding-top: $global-spacing-unit;
}

.pb-spacing {
  padding-bottom: $global-spacing-unit;
}

.pr-spacing {
  padding-right: $global-spacing-unit;
}

.pl-spacing {
  padding-left: $global-spacing-unit;
}

.p-spacing--medium {
  padding: $global-spacing-unit-large;
}

.p-spacing--large {
  padding: $global-spacing-unit-huge;
}

.p-none {
  padding: 0 !important;
}

.pb-spacing-none {
  padding-bottom: 0;
}

/* ---- Display ---- */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

/* ---- Float ---- */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/* ---- elevation appearance - drop shadows ---- */
.elevation {
  @include elevation;
}

/* ---- Others ---- */
.ul-plain {
  list-style: none;
}

.overflow {
  &--auto {
    overflow: auto;
  }
}