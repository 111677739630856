/*
############     _elements-index.scss      ############
elemental html: h1, h2, h3, ul, li, etc.
*/

html {
  font-family: $normalFont;
  font-weight: $normalFontBaseWeight;
  font-size: $baseFontSize;
  color: $baseColor;
}

h1 {
  padding: 20px 0;
  font-size: 3em;
  margin: 0 0 $global-spacing-unit-large 0;
}

h2,
h3 {
  font-size: 2.5rem;
  margin: 0 0 $global-spacing-unit 0;
}

h4 {
  font-size: 1.75rem;
}

p, ul, ol {
  line-height: $global-line-height;
  margin: 0 0 $global-spacing-unit 0;
}

p {
  font-size: 1.5em;
}

a {
  color: $primaryColor;
  text-decoration: underline;
  cursor: pointer;

  &:visited {
    color: $primaryColor;
  }

  &:hover,
  &:active {
    color: $primaryColorDark;
  }
}

.svg-icon {
  height: 1em;
  width: 1em;
}

hr {
  border: 0;
  border-top: 1px solid $gray20;
  margin: 0 0 $global-spacing-unit 0;
}