
.all_rules {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .input-container {
    width: 1000px;
  }
}

.is-starred, .is-not-starred {
  cursor: pointer;
}

.is-starred {
  stroke: gold;
}

.is-not-starred {
  stroke: grey;
}
