.icon {
  &--round {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: #FFF;

    &.error {
      background-color: $red;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin: 0 auto;
      fill: $complementaryColor;
    }
  }
}