.benefits {
  transform: translate3d(100vw, 0, 0);
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  background: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 4rem);
  z-index: 9999;
  padding: 2rem;
  text-align: left;
  overflow-y: auto;

  &--header {
    margin: 2rem 0;
  }

  &--table {
    .svg-icon {
      width: 24px;
      height: 24px;
    }

    th.note {
      position: relative;
    }

    th.note:after {
      content: "*";
      color: $red;
      position: absolute;
      top: calc(1rem - 4px);
      margin-left: 4px;
    }
  }

  &--sub-title {
    font-size: 1.5rem !important;
  }

  &--description {
    font-size: 1rem;
  }

  &--list {
    h5 {
      text-transform: uppercase;
      margin: 0 0 .5rem 0;
      font-size: 1rem;
    }
  }

  &.visible {
    transform: translate3d(0, 0, 0);
  }

  .backdrop {
    z-index: 9998 !important;
  }

  .close--button {
    display: inline-block;
    cursor: pointer;
    width: 32px;
    height: 32px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--graph {
    min-height: 500px;

    table {
      tr:nth-child(even) {
        background-color: white;
      }
    }
  }

  .disclaimer {
    font-size: 1rem;
  }
}

