/*
############     _form-error.scss      ############
*/
.form-error {
  display: block;
  position: relative;
  border: 2px solid $red;
  color: $red;
  background: rgba(222, 90, 82, .17);
  padding: $global-spacing-unit-small;
  border-radius: 4px;
  margin: 8px 0 0 0;
  width: auto;
  text-align: center;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 5px);
    top: -4px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent $red transparent;
  }

  &.no-arrow {
    &:before {
      content: none;
    }
  }
}