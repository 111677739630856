/*
############     _settings-index.scss      ############
variables and settings
*/

// COLORS
$gray01: #FCFCFC;
$gray03: #F7F7F7;
$gray05: #F2F2F2;
$gray08: #EAEAEA;
$gray10: #E6E6E6;
$gray13: #DDDDDD;
$gray20: #CCCCCC;
$gray25: #BFBFBF;
$gray30: #B2B2B2;
$gray40: #999999;
$gray50: #808080;
$gray60: #666666;
$gray68: #444444;
$gray70: #4C4C4C;
$gray80: #333333;
$gray90: #191919;

/*
	Color generator / color shades
	https://coolors.co/007eff-1f2e3d-004e64-25a18e-ff5a36
 */

$primaryColor: #518DDE;
$primaryColorLight: #E7F3FF;
$primaryColorDark: #4076bf;

$complementaryColor: #DE5A51;
$complementaryColorLight: #FFF0EC;
$complementaryColorDark: #CB5047;

$secondaryColor: #FFD9D7;
$secondaryColorLight: #EBF6F4;
$secondaryColorDark: #FDA9A4;

$tertiaryColor: #004E64;
$tertiaryColorLight: #E7EEF0;
$tertiaryColorDark: #00242E;

$quaternaryColor: #1F2E3D;
$quaternaryColorLight: #EAECED;
$quaternaryColorDark: #111E22;

$neutralColor: #647B82;
$neutralColorLight: #F0F3F3;
$neutralColorDark: #374447;

//base color for text, etc.
$baseColor: $gray80;

// Used for .red-text and required "*"
$red: #DE5A51;
// Used for form errors
$error: #FF5A36;

// DROP SHADOW COLOR - see elevation mixin below
$dsc: rgb(0, 0, 0);

$alertsDefaultColor: $gray70;
$alertsSuccessColor: #157d26;
$alertsWarningColor: #a30000;
$alertsPrimaryColor: $primaryColor;
$alertsComplimentaryColor: $complementaryColor;
$alertsSecondaryColor: $secondaryColor;

// Graph colors
$graphColor0: #44AA99;
$graphColor0Light: #62c5b9;
$graphColor1: #117733;
$graphColor1Light: #4aa172;
$graphColor2: #999933;
$graphColor2Light: #baba71;
$graphColor3: #DDCC77;
$graphColor3Light: #ecdd9f;
$graphColor4: #88CCEE;
$graphColor4Light: #98ddf4;
$graphColor5: #AA4499;
$graphColor5Light: #dc80bc;
$graphColor6: #CC6677;
$graphColor6Light: #f396a3;
$graphColor7: #882255;
$graphColor7Light: #c7688c;
$graphColor8: #332288;
$graphColor8Light: #7968b1;

$colorGraphList: $graphColor0 $graphColor1 $graphColor2 $graphColor3 $graphColor4 $graphColor5 $graphColor6 $graphColor7 $graphColor8;
$colorGraphLightList: $graphColor0Light $graphColor1Light $graphColor2Light $graphColor3Light $graphColor4Light $graphColor5Light $graphColor6Light $graphColor7Light $graphColor8Light;

// FONT SIZES
$baseFontSize: 16px;
$xsmallFontSize: 0.7rem;
$vsmallFontSize: 0.8rem;
$smallFontSize: 0.9rem;
$largeFontSize: 1.5rem;
$vlargeFontSize: 2.0rem;
$xlargeFontSize: 2.5rem;

// FONTS
$normalFont: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
$normalFontBaseWeight: 400;
$fixedWidthFont: $normalFont;
$fixedWidthFontBaseWeight: 400;
$boldFontWeight: 700;

$global-line-height: calc(1rem * 1.61803398875);

// FORM ELEMENTS
$formBorderWidth: 1px;
$formBorder: $formBorderWidth solid $gray25;
$formErrorBorder: $formBorderWidth solid $error;
$formBorderRadius: 3px;
$formBgColor: white;
$formElementHeight: 30px;
$buttonHeight: $formElementHeight;
$formColor: $gray80;
$formColorEmpty: $gray50;

//input focus outline color
$focusColor: #3B99FC;

// FORM ELEMENT SIZES
$xsmall: 100px;
$small: 150px;
$medium: 230px;
$large: 300px;
$xlarge: 400px;
$xxlarge: 500px;
$fullWidth: 100%;

$labelWidth: 20%;
$labelWidthFull: 100%;

// SPACING LAYOUT
$global-spacing-unit: 1rem;
$global-spacing-unit-tiny: .25rem;
$global-spacing-unit-small: .5rem;
$global-spacing-unit-medium: .75rem;
$global-spacing-unit-large: 1.5rem;
$global-spacing-unit-huge: 2rem;
$global-spacing-unit-xhuge: 4rem;

/*
	-------------------- MIXINS --------------------
 */
@mixin formElementSizes {
  &.size-xsmall {
    width: $xsmall;
  }
  &.size-small {
    width: $small;
  }
  &.size-medium {
    width: $medium;
  }
  &.size-large {
    width: $large;
  }
  &.size-xlarge {
    width: $xlarge;
  }
  &.size-xxlarge {
    width: $xxlarge;
  }
  &.size-full {
    width: 100%;
  }
}

@mixin formElementSizesStacked {
  &.size-full {
    width: 100%;
  }
}

@mixin elevation {
  &--z2 {
    box-shadow: 0 3px 1px -2px rgba($dsc, .2), 0 2px 2px 0 rgba($dsc, .14), 0 1px 5px 0 rgba($dsc, .12)
  }
  &--z4 {
    box-shadow: 0 2px 4px -1px rgba($dsc, .2), 0 4px 5px 0 rgba($dsc, .14), 0 1px 10px 0 rgba($dsc, .12);
  }
  &--z6 {
    box-shadow: 0 3px 5px -1px rgba($dsc, .2), 0 6px 10px 0 rgba($dsc, .14), 0 1px 18px 0 rgba($dsc, .12);
  }
}