.results {
  &-wrapper {
    p {
      font-size: 1rem;
    }
  }

  &--item {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .45);
    margin-bottom: 2rem;
    border-radius: 4px;

    h5 {
      text-transform: uppercase;
      margin: 0 0 .5rem 0;
      font-size: 1rem;
    }

    h3 {
      font-size: 2rem;
    }

    button {
      min-height: 3rem;
    }

    .disclaimer {
      font-size: .75rem;
    }
  }
}