.home-wrapper {
  min-height: 100vh;
  position: relative;
  padding: 1.5rem 2rem;

  .home-content {
    z-index: 10;
  }

  .buttons {
    margin-bottom: $global-spacing-unit-large;
  }

  .home-form {
    border-radius: 4px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .45);
    max-width: 500px;
    margin: 50px auto;
    padding: 40px 60px;
    text-align: left;
    font-size: 1.25rem;

    .home-button {
      width: 100%;
    }
  }

  .home-footer {
    height: 50%;
    position: absolute;
    background: $gray68;
    width: 100%;
    bottom: 0;
    z-index: -1;
  }

  .home-actions {
    display: flex;
    flex-direction: column;

    button {
      max-width: 250px;
      margin-bottom: 1rem;
    }
  }
}