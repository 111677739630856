/*
############     _pop-over.scss      ############
*/
$popover-arrow-size: 12px;
$popover-arrow-color: #FFF;

.popover {

  &__anchor {
    display: inline-block;

    .full-width & {
      width: $fullWidth;
    }
  }

  &__popup {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    padding: .5rem;
    //border: 1px solid $popover-arrow-color;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
    border-radius: 4px;
    animation: 0.75s popover-fade-in;
    display: inline-block;
  }

  &__arrow {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid red;

    &--top {
      border-left: $popover-arrow-size solid transparent;
      border-right: $popover-arrow-size solid transparent;
      border-top: $popover-arrow-size solid $popover-arrow-color;
    }

    &--bottom {
      border-left: $popover-arrow-size solid transparent;
      border-right: $popover-arrow-size solid transparent;
      border-bottom: $popover-arrow-size solid $popover-arrow-color;
    }

    &--left {
      border-top: $popover-arrow-size solid transparent;
      border-bottom: $popover-arrow-size solid transparent;
      border-left: $popover-arrow-size solid $popover-arrow-color;
    }

    &--right {
      border-top: $popover-arrow-size solid transparent;
      border-bottom: $popover-arrow-size solid transparent;
      border-right: $popover-arrow-size solid $popover-arrow-color;
    }
  }
}

@keyframes popover-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}