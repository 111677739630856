/*
############     _button.scss      ############
*/
button:not(.hiveburger, .react-datepicker__navigation) {
  background: $complementaryColor;
  border-radius: $formBorderRadius;
  border: 1px solid $complementaryColor;
  color: white;
  cursor: pointer;
  fill: white;
  font-family: $normalFont;
  font-weight: bold;
  min-height: $global-spacing-unit-xhuge;
  padding: 0 $buttonHeight / 2;
  position: relative;
  transition: all 200ms ease;
  text-transform: capitalize;
  //box-shadow: 3px 3px 0 $gray40;

  &:hover {
    background: $complementaryColorDark;
    color: white;
    fill: white;
  }

  &.complementary {
    color: $complementaryColor;
    fill: $complementaryColor;
    border-color: $complementaryColor;

    &:hover {
      background: $complementaryColor;
      color: white;
      fill: white;
    }
  }

  &.secondary {
    color: $complementaryColor;
    background: $secondaryColor;
    fill: $secondaryColor;
    border-color: $secondaryColor;

    &:hover {
      background: $secondaryColorDark;
      color: $complementaryColor;
      fill: white;
    }
  }

  &.neutral {
    color: $neutralColorDark;
    fill: $neutralColor;
    border-color: $neutralColor;

    &:hover {
      background: $neutralColor;
      color: white;
      fill: white;
    }
  }

  &[disabled],
  &[disabled]:hover {
    color: $gray30;
    border-color: $gray40;
    background: white;
    cursor: default;

    &.button--solid,
    &.button--solid.complementary,
    &.button--solid.secondary,
    &.button--solid.neutral {
      color: $gray05;
      background: $gray30;
      border-color: $gray30;
    }
  }

  &.button--solid {
    color: white;
    fill: white;
    background: $gray50;
    border-color: $gray50;

    &:hover {
      background: $gray30;
      border-color: $gray30;
    }

    &.complementary {
      background: $complementaryColor;

      &:hover {
        background: $complementaryColorDark;
        border-color: $complementaryColorDark;
      }
    }

    &.secondary {
      background: $secondaryColor;

      &:hover {
        background: $secondaryColorDark;
        border-color: $secondaryColorDark;
      }
    }

    &.neutral {
      background: $neutralColor;

      &:hover {
        background: $neutralColorDark;
        border-color: $neutralColorDark;
      }
    }
  }

  &.button--small {
    font-size: .9rem;
    min-height: $buttonHeight - 4px;
  }

  &.button--large {
    font-size: 1.1rem;
    min-height: $buttonHeight + 10px;
  }

  &.button--icon {
    .button-label {
      padding: 0 1.2rem 0 0;
    }

    svg + .button-label {
      padding: 0 0 0 1.2rem;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(10px, -50%);
    }

    .button-label + svg {
      left: 100%;
      transform: translate(calc(-100% - 10px), -50%);
    }

    &.button--noLabel svg {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.button--noChildren .button-label {
      padding: 0;
    }
  }

  &.button--naked {
    background: none;
    border: none;
    color: $primaryColor;
    font-weight: normal;
    box-shadow: none;
    min-height: auto;

    .button-label {
      text-decoration: underline;
    }

    &:hover {
      .button-label {
        color: $primaryColorDark;
      }

      svg {
        fill: $primaryColorDark;
      }
    }

    &.red {
      border: 1px solid $red;
      color: $red;
      min-height: 40px;

      &:hover {
        background: #CB5047;
        color: white;
        fill: white;
      }
    }
  }

  &.button--black {
    background: $gray68;
    border-color: $gray68;

    &:hover {
      background: $gray50;
      border-color: $gray50;
    }
  }

  &.busy {
    padding: 0 2.5rem 0 1.2rem;

    .css-spinner {
      display: block;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(calc(-100% - 10px), -50%);
    }
  }

  &.triggered {
    opacity: .5;
  }

  &.button--square {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
    padding: 0;
  }

  &.medium--size {
    min-height: 3rem;
    font-size: 1rem;
  }

  &.button--short {
    min-height: 2rem;

    .icon--round {
      width: 1.25rem;
      height: 1.25rem;

      svg {
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  }

  &.float-right {
    margin-right: 4px;
  }
}