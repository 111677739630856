.actions {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    margin: .75rem;
    display: flex;
    align-items: baseline;
    cursor: pointer;

    svg {
      margin-right: .75rem;
    }

    &:hover {
      color: $gray40;

      svg {
        fill: $gray68;
      }
    }
  }
}

.drug-name {
  font-size: $global-spacing-unit-large;
  font-weight: normal;
}