/*
############     _select-input.scss      ############
*/
$formElementHeight: 45px;

.react-select-wrapper {
  display: inline-block;
  vertical-align: middle;

  @include formElementSizes;

  .react-select {
    &__control {
      border: $formBorder;
      background: $formBgColor;
      border-radius: $formBorderRadius;
      min-height: $formElementHeight;
      font-family: $fixedWidthFont;
      font-weight: $fixedWidthFontBaseWeight;

      &--is-focused {
        border-color: $focusColor;
        box-shadow: 0 0 2px 2px rgba($focusColor, 0.5);
      }
    }

    &__value-container {
      padding: 0 8px;

      &--is-multi.react-select__value-container--has-value {
        padding: 0 8px 0 3px;
      }
    }

    &__single-value {
      position: relative;
      transform: none;
      flex: 2;
      max-width: 95%;
      white-space: normal;
      text-align: left;
    }

    &__multi-value__label {
      white-space: normal;
    }

    &__placeholder {
      top: unset;
      margin: 0;
      position: relative;
      transform: none;
    }

    &__input {
      input[type=text] {
        height: auto;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicators {
      background: white;
    }

    &__indicator {
      padding: 0 3px;

      svg {
        fill: $gray25;
      }
    }

    &__clear-indicator {
      align-self: stretch;
      align-items: center;
      background: $gray40;

      &:hover {
        background-color: #FFBDAD;

        svg {
          fill: #DE350B;
        }
      }
    }
  }
}

/* Table Filter Specific */
table.filters {
  tr.filters {
    .react-select-wrapper {
      width: 100%;

      &.react-select--single-select {
        .react-select {
          &__control {
            flex-wrap: nowrap;
          }

          &__value-container {
            flex-wrap: nowrap;
            overflow: visible;
          }
        }
      }

      .react-select {
        &__control {
          border: none;
          border-radius: 0;
          background: none;
          min-width: 100px;

          &--is-focused {
            border: none;
            box-shadow: none;
          }
        }

        &__placeholder {
          color: $gray13;
        }

        &__multi-value {
          &__label {
            overflow: visible;
          }
        }

        &__value-container {

        }

        &__indicators {
          background: $gray13;
        }

        &__indicator {
          padding: 0;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}