/* ########     POPUP WINDOW     ######## */
$small-popup-size: 500px;
$large-popup-size: 700px;
$popup-margins: 32px;

.popup-window {
  position: absolute;
  z-index: 10010;
  left: 50%;
  top: 0;
  margin: $global-spacing-unit-xhuge 0;
  transform: translateX(-50%);
  background: white;
  min-width: $small-popup-size;
  animation: 400ms ease-in-out 100ms drop-in both;
  border-radius: 5px;
  overflow: visible;

  &--large {
    min-width: $large-popup-size;
  }

  &__backdrop {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    animation: 300ms ease-in 100ms fade-in both;
    overflow: auto;
  }

  &__content {
    padding: 30px;

    p {
      font-size: 1rem;
    }

    .input-container label:not(.form-error) {
      text-align: left;
    }

    textarea {
      padding: $global-spacing-unit-small;
    }
  }

  &__close-button {
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 12px;
    height: 12px;
    text-indent: 20000px;
    overflow: hidden;

    svg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      fill: $red;
    }
  }

  &__footer {
    //padding: 0 $global-spacing-unit-huge $global-spacing-unit-huge $global-spacing-unit-huge;
    text-align: right;
    clear: both;

    button {
      width: 50%;
      border-radius: 0 0 4px 0;

      &:first-child {
        border-radius: 0 0 0 4px;
      }

      &:only-child {
        width: 100%;
        border-radius: 0 0 4px 4px;
      }
    }
  }
}

@media only screen and (max-width: $large-popup-size + $popup-margins) {
  .popup-window--large {
    min-width: calc(100vw - #{$popup-margins});
  }
}

@media only screen and (max-width: $small-popup-size + $popup-margins) {
  .popup-window {
    min-width: calc(100vw - #{$popup-margins});
  }
}