/*
############     _table.scss      ############
*/
table {
  width: 100%;
  border-collapse: collapse;

  tr {
    transition: all 100ms ease-in-out;

    &:nth-child(even) {
      background-color: $gray13;
    }

    &.drag-drop-is-over {
      background: $primaryColor;
      color: #ffffff;
    }

    &.mouse-over a {
      color: #ffffff;
    }

    &.show-all-records-row {
      td {
        text-align: center;
      }
    }

    td, th {
      border: none;
      padding: 1rem 0.5rem;
      text-align: left;
    }

    &.level0 {
      font-weight: bold;
    }

    &.level1 {
      font-weight: bold;

      & td:first-child {
        padding: 0 0 0 2rem;
      }
    }

    &.level2 {
      & td:first-child {
        padding: 0 0 0 4rem;
      }
    }

    th {
      border-bottom: 1px solid $gray80;

      &.sortable {
        position: relative;
        cursor: pointer;

        &.sortAscending, &.sortDescending {
          border-bottom: 4px solid $red;
          padding: 0.5rem 1rem 0.5rem 0.5rem;

          &::after {
            content: " ";
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            border: solid $red;
            border-width: 0 2px 2px 0;
            top: calc(50% - 4px);
            right: 5px;
            transform: rotate(-135deg) translateY(-50%);
          }
        }

        &.sortDescending {
          &::after {
            top: calc(50% - 3px);
            right: 10px;
            transform: rotate(45deg) translateY(-50%);
          }
        }
      }
    }
  }

  &.filters {
    & thead {
      tr:nth-child(2) {
        background-color: $gray05;

        td {
          padding: 0;
          border-right: 2px solid #FFF;
        }

        td:last-child {
          border-right: none;
        }
      }

      div.table-date-filter {
        div.popover__anchor {
          width: 100%;

          div.date-range-filter-placeholder {
            color: $gray13;
            font-family: $fixedWidthFont;
            user-select: none;
          }
        }

        &__current-value {
          margin: $global-spacing-unit-tiny;
          text-align: center;
          font-size: $smallFontSize;
          color: $primaryColor;
        }

        &__from {
          padding-bottom: $global-spacing-unit-tiny;
          border-bottom: 1px solid $gray13;
        }

        &__to {
          padding-top: $global-spacing-unit-tiny;
        }
      }

      .react-select__single-value,
      .react-select__multi-value__label,
      .filter-input {
        color: $primaryColor;
      }

      & svg.filter-icon {
        fill: $gray13;
        position: absolute;
        height: 27px;
        padding: 9px 4px;
      }

      & input[type="text"],
      div.date-range-filter-placeholder {
        border: none;
        padding: 5px 10px 5px 1.5rem;
        width: 100%;
        background: transparent;

        &::placeholder {
          color: $gray13;
        }
      }

      & div.Select {
        width: 100%;
      }

      .table-date-filter {
        .popover__popup {
          padding: 0;

          .date-filter-title {
            padding: 10px;
            text-align: center;
            font-weight: bold;
          }

          .input-container {
            margin: 0 10px 5px 10px;
          }

          label {
            width: 50px;
          }

          input[type="text"] {
            border: $formBorder;
            width: 115px;
            background: white;
            padding: 0.5rem;
          }

          .date-filter-buttons button {
            border: none;
            border-radius: 0;
          }
        }
      }
    }

    & thead th {
      border-bottom: none;
    }
  }

}
