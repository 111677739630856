/*
############     _collapsible-section.scss      ############
*/

.collapsible-section {

  &__title,
  &__title:visited {
    display: flex;
    background: $primaryColor;
    color: white;
    padding: $global-spacing-unit;
    text-decoration: none;
  }

  &__title:hover {
    background: $primaryColorDark;
    color: white;
  }

  &__content {
    padding: $global-spacing-unit 0;
    line-height: $global-line-height;
  }
}

.collapsible-section__title-text {
  flex: 1;
}

.collapsible-section__title-indicator > * {
  position: relative;
  fill: currentColor;
  transition: all 200ms;
}

.collapsible-section__header.open .collapsible-section__title-indicator > * {
  transform-origin: center;
  transform: rotate(90deg);
}

.collapsible-section + .collapsible-section {
  margin: $global-spacing-unit 0 0 0;
}
