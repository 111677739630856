/*
############     _tip-index.scss      ############
A place to add css temporarily
NOTE: CSS added this this file should eventually be removed or
located to the correct folder/file where it best belongs.
*/

.ellipsis {
  svg {
    fill: $gray80;
    min-height: 1.25rem;
    min-width: 1.25rem;
  }

  &:focus {
    outline: none;
  }
}
